<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :loaded.sync="entityLoaded"
  >
    <b-card v-if="entityLoaded">
      <b-tabs pills>
        <!-- Tab: Information -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Basic Information') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="infoFormFields"
            allow-autofocus
          />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Contact') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="contactFormFields"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="ImageIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Profile Photo') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="profilePhotoFormFields"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="DollarSignIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Billing Info') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="billingFormFields"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </EntitySingle>
</template>

<script>
import { BTab, BTabs, BCard } from 'bootstrap-vue'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import { translationUtils } from '@core/mixins/ui/translations'
import storeModule from '../PersonStoreModule'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
    BTab,
    BTabs,
    BCard,
  },
  mixins: [translationUtils],
  data() {
    return {
      entity: {
        enabled: true,
        country: { id: 263, title: 'Greece' },
        participant: {},
      },
      entityLoaded: false,
      specialtyOptions: [],
      professionOptions: [],
      countryOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'persons',
        endpoint: 'persons',
        route: 'persons',
        title: {
          single: this.$t('Person Add'),
          plural: this.$t('Persons'),
        },
      }
    },

    infoFormFields() {
      return [
        {
          id: 'firstName',
          type: 'text',
          label: this.$i18n.t('First Name'),
          required: true,
          colSize: 6,
        },
        {
          id: 'lastName',
          type: 'text',
          label: this.$i18n.t('Last Name'),
          required: true,
          colSize: 6,
        },
        {
          id: 'email',
          type: 'email',
          label: this.$i18n.t('Email'),
          required: true,
          rules: 'email',
          colSize: 6,
        },
        {
          id: 'email2',
          type: 'email',
          label: this.$i18n.t('Email 2'),
          rules: 'email',
          colSize: 6,
        },
        {
          id: 'email3',
          type: 'email',
          label: this.$i18n.t('Email 3'),
          rules: 'email',
          colSize: 6,
        },
        {
          id: 'birthDate',
          type: 'date',
          label: this.$i18n.t('Birth Date'),
          colSize: 6,
        },
        {
          id: 'professionalTitle',
          type: 'text',
          label: this.$i18n.t('title'),
          colSize: 6,
        },
        {
          id: 'profession',
          type: 'select',
          object: true,
          label: this.$i18n.t('Profession'),
          options: this.professionOptions,
          colSize: 6,
        },
        {
          id: 'specialty',
          type: 'select',
          object: true,
          label: this.$i18n.t('Field Of Expertise'),
          options: this.specialtyOptions,
          colSize: 6,
        },
        {
          id: 'organization',
          type: 'text',
          label: this.$i18n.t('Organization'),
          colSize: 6,
        },
        {
          id: 'honorTitle',
          type: 'text',
          label: this.$i18n.t('Honor Title'),
          colSize: 6,
        },
      ]
    },
    contactFormFields() {
      return [
        {
          id: 'country',
          object: true,
          type: 'select',
          label: this.$t('country'),
          options: this.countryOptions,
          required: true,
          colSize: 6,
        },
        {
          id: 'city',
          type: 'text',
          label: this.$t('city'),
          colSize: 6,
        },
        {
          id: 'telephone',
          type: 'text',
          label: this.$i18n.t('telephone'),
          rules: 'phone',
          colSize: 3,
        },
        {
          id: 'mobile',
          type: 'text',
          label: this.$i18n.t('mobile'),
          rules: 'phone',
          colSize: 3,
        },
        {
          id: 'address',
          type: 'text',
          label: this.$i18n.t('address'),
          colSize: 6,
        },
        {
          id: 'postalCode',
          type: 'text',
          label: this.$t('zip'),
          colSize: 3,
        },
        {
          id: 'fax',
          type: 'text',
          label: this.$i18n.t('fax'),
          rules: 'phone',
          colSize: 3,
        },
        {
          id: 'website',
          type: 'text',
          label: this.$t('website'),
          rules: 'url',
          colSize: 6,
        },
        {
          id: 'map',
          type: 'map',
          colSize: 12,
        },
      ]
    },
    billingFormFields() {
      return [
        {
          id: 'companyName',
          type: 'text',
          label: this.$i18n.t('company name'),
          colSize: 6,
        },
        {
          id: 'vatOffice',
          type: 'text',
          label: this.$i18n.t('vat office'),
          colSize: 6,
        },
        {
          id: 'vatId',
          type: 'text',
          label: this.$i18n.t('vat'),
          colSize: 6,
        },
        {
          id: 'vat',
          type: 'text',
          label: this.$i18n.t('tax'),
          rules: 'positive',
          colSize: 3,
        },
      ]
    },
    profilePhotoFormFields() {
      return [
        {
          id: 'profilePhoto',
          type: 'cropupload',
          label: `${this.$i18n.t('Profile Photo')} (512x512)`,
          colSize: 4,
          width: 512,
          height: 512,
          autoupload: true,
        },
        {
          id: 'bio',
          type: 'quill',
          label: this.$i18n.t('bio'),
          colSize: 8,
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchSpecialties').then(response => {
      this.specialtyOptions = response.data
    })
    this.$store.dispatch('app-common/fetchProfessions').then(response => {
      this.professionOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCountries').then(response => {
      this.countryOptions = response.data
    })
  },
}
</script>
